<template>
  <div class="container-fluid container-xl signup">
    <div class="header">
      <router-link to="/">
        <img src="../../../assets/logo-transparent-cropped.png" alt="" />
      </router-link>
    </div>
    <div class="content">
      <router-view> </router-view>
    </div>

    <div class="footer">
      <p>Copyright JabComp Ⓒ {{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.signup {
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top:100px;
  .header {
    width: 100%;
    height: 100px;
    background: #005eaa33;
    text-align: center;
    padding: 15px;
    border-radius: 0 0 70px 70px;
    img {
      height: 100%;
      max-width: 90%;
      object-fit: contain;
    }
  }

  .content {
    height: 100%;
    width: 100%;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #005eaa33;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px 20px 0 0;

    > p {
      margin: 0;
    }
  }
}
</style>